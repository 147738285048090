import { useMemo } from "react";

import { Icon } from "@chakra-ui/icon";
import { Button } from "../Common";
import { DownloadIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";
import _ from "lodash";
import { ENUMS } from "@/Constants";

import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import { IPerformanceFilter } from "@/Interfaces/Performance.interface";
import { htmlToText } from "html-to-text";
import { useTranslation } from "react-multi-lang";

const headerExceljs = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
];

const ExportExcelPerformance = () => {
  const payload: any = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "payload")
  );
  const t = useTranslation();

  const sortUserByName = (array: any[], descending = false) => {
    const sortOrder = descending ? -1 : 1;
    const sortedArray = [...array];
    sortedArray.sort((a, b) => {
      const valueA = String(a?.user?.userData?.fullName)?.toLowerCase();
      const valueB = String(b?.user?.userData?.fullName)?.toLowerCase();

      if (valueA < valueB) return -1 * sortOrder;
      if (valueA > valueB) return 1 * sortOrder;
      return 0;
    });
    return sortedArray;
  };

  const pagination: IPerformanceFilter = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "pagination")
  );

  const filteredProjects = useMemo(() => {
    return _.filter(
      payload,
      (project) => project.status !== ENUMS.PROJECT_STATUS.WAITING_PROCESS
    );
  }, [payload]);

  const titleCase = (string: string) => {
    let sentence = string.toLowerCase().split(",");
    console.log(sentence);

    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    //return sentence.join(",");
    return t(`label.${sentence.join(",")}`);
  };

  async function exportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 25;
    worksheet.getColumn(8).width = 25;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 25;
    worksheet.getColumn(11).width = 45;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;

    // Add new rows at the beginning
    worksheet.getCell("A1").value = "Type";
    worksheet.getCell("B1").value = "project";

    //title
    worksheet.getCell("A2").value = "Performance Evaluation";
    worksheet.mergeCells("A2:M2");

    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.getCell("A2").font = {
      size: 20,
      bold: true,
      color: { argb: "FFFFFF" },
    };

    worksheet.getCell("A2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet.getRow(2).height = 30;
    if (pagination?.startDate && pagination?.endDate) {
      worksheet.getCell("A3").value = `${dayjs(pagination?.startDate).format(
        "DD/MM/YYYY"
      )} - ${dayjs(pagination?.endDate).format("DD/MM/YYYY")}`;
      worksheet.mergeCells("A3:M3");
      worksheet.getCell("A3").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      worksheet.getCell("A3").font = {
        size: 13,
        bold: false,
        color: { argb: "FFFFFF" },
      };
      worksheet.getCell("A3").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4F81BD" },
      };
      worksheet.getRow(3).height = 20;
    }

    //header
    worksheet.addRow([
      t("table.no"),
      t("table.projects"),
      t("table.projectCode"),
      t("table.serverLink"),
      t("table.client"),
      t("table.status"),
      t("table.managers"),
      t("table.members"),
      t("table.staffCode"),
      t("table.process"),
      t("table.workContent"),
      t("table.amountOfWork"),
      t("table.performance"),
      //  "Note",
      //  "Reviewer",
    ]);

    _.forEach(headerExceljs, (i) => {
      worksheet.getCell(`${i}3`).border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B4C6E7" },
      };
      cell.font = { size: 12, bold: true, color: { argb: "000000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //data

    const sortedClient = _.sortBy(filteredProjects, ["name"]);
    const sortedProjects = _.sortBy(sortedClient, [
      (item) => _.get(item, "clientNew.name", "").toLowerCase(),
    ]);
    sortedProjects.forEach((item, index) => {
      const managers = item?.projectMember.filter((item: any) => {
        return _.some(
          item?.user?.userRole,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
        );
      });
      // Hide leaders when user permission is only leader
      const leaders = item?.projectMember.filter((item: any) => {
        return _.some(
          item?.user?.userRole,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.LEADER
        );
      });
      const sortedLeaders = sortUserByName(leaders, false);

      const artists = item?.projectMember.filter((item: any) => {
        const hasArtistRole = _.some(
          item?.user?.userRole,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
        );

        const hasLeaderRole = _.some(
          item?.user?.userRole,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.LEADER
        );

        const hasMarketingRole = _.some(
          item?.user?.userRole,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MARKETING
        );

        return hasArtistRole && !hasLeaderRole && !hasMarketingRole;
      });

      const markerting = item?.projectMember.filter((item: any) => {
        const hasMarketingRole = _.some(
          item?.user?.userRole,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MARKETING
        );

        //const hasArtistRole = _.some(
        //  item?.user?.userRole,
        //  (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
        //);

        const hasLeaderRole = _.some(
          item?.user?.userRole,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.LEADER
        );

        return hasMarketingRole && !hasLeaderRole;
      });

      const sortedMarketings = sortUserByName(markerting, false);

      const sortedArtists = sortUserByName(artists, false);

      const members = [...sortedLeaders, ...sortedArtists, ...sortedMarketings];
      const sortedMembers = _.sortBy(members, [
        (item) => _.get(item, "user.userData.fullName", "").toLowerCase(),
      ]);

      const updatedMembers = sortedMembers.map((member) => {
        const matchingPerformance = item?.performanceEvaluation?.find(
          (evaluation: any) => evaluation?.user?.id === member?.user?.id
        );

        if (matchingPerformance) {
          return {
            ...member,
            performance: matchingPerformance,
          };
        }

        return member;
      });

      if (worksheet.rowCount !== 2) {
        worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
          cell.font = { size: 12, bold: false, color: { argb: "233E7C" } };
        });
      }

      worksheet.getRow(worksheet.rowCount).height = 25;

      worksheet.getRow(worksheet.rowCount).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      let totalColumn: number = 0;
      const rowNumber = index + 1;
      updatedMembers.forEach((member) => {
        //const rowNumber = memberIndex === 0 ? index + 1 : "";
        
        const logCount = member?.user?.logTime.length;
        totalColumn +=
          member?.user?.logTime.length !== 0 ? member?.user?.logTime.length : 1;
        const logTimes = member?.user?.logTime;
        if (logTimes?.length > 0) {
          item?.kanbanBoard?.task.forEach((task: any) => {
            let taskCount = 0;
            logTimes.forEach((log: any) => {
              const taskId = log?.task?.id;
              if (taskId === task?.id) {
                taskCount++;
                const newRow = worksheet.addRow([
                  rowNumber,
                  item.name,
                  item?.code,
                  item.serverPath,
                  item.clientNew?.name,
                  titleCase(item.status.toString()),
                  managers.map((x: any) => x.user.userData.fullName).join(","),
                  member?.user?.userData?.fullName,
                  member?.user?.staffCode,
                  task?.title,
                  log?.description ? htmlToText(log?.description) : "-",
                  member?.performance?.amountOfWork,
                  member?.performance?.performance,
                  //member?.performance?.note,
                  //member?.performance?.userReview?.userData?.fullName,
                ]);

                newRow.eachCell((cell) => {
                  cell.font = {
                    size: 12,
                    bold: false,
                    color: { argb: "233E7C" },
                  };
                });

                newRow.height = 25;
                newRow.alignment = {
                  vertical: "middle",
                  horizontal: "center",
                };
              }
            });

            if (taskCount > 0) {
              const mergeStartRowTask = worksheet.rowCount - taskCount + 1;
              const mergeEndRowTask = worksheet.rowCount;
              _.forEach(headerExceljs, (i) => {
                for (let x = mergeStartRowTask; x <= mergeEndRowTask; x++) {
                  worksheet.getCell(`${i}${x}`).border = {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                  };
                }
              });
              worksheet?.mergeCells(mergeStartRowTask, 10, mergeEndRowTask, 10);
            }
          });
        }
        if (logTimes.length === 0) {
          //  item?.kanbanBoard?.task.forEach((task: any) => {
          const newRow = worksheet.addRow([
            rowNumber,
            item.name,
            item?.code,
            item.serverPath,
            item.clientNew?.name,
            titleCase(item.status.toString()),
            managers.map((x: any) => x.user.userData.fullName).join(","),
            member?.user?.userData?.fullName,
            member?.user?.staffCode,
            //item?.kanbanBoard?.task.map((x: any) => x.title).join(","),
            "",
            "",
            member?.performance?.amountOfWork,
            member?.performance?.performance,
          ]);

          newRow.eachCell((cell) => {
            cell.font = { size: 12, bold: false, color: { argb: "233E7C" } };
          });

          newRow.height = 25;
          newRow.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
        }

        if (logCount >= 1) {
          const mergeStartRow = worksheet.rowCount - logCount + 1;
          const mergeEndRow = worksheet.rowCount;
          _.forEach(headerExceljs, (i) => {
            for (let x = mergeStartRow; x <= mergeEndRow; x++) {
              worksheet.getCell(`${i}${x}`).border = {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
              };
            }
          });

          worksheet?.mergeCells(mergeStartRow, 8, mergeEndRow, 8);
          worksheet?.mergeCells(mergeStartRow, 9, mergeEndRow, 9);
          worksheet?.mergeCells(mergeStartRow, 12, mergeEndRow, 12);
          worksheet?.mergeCells(mergeStartRow, 13, mergeEndRow, 13);
        }
      });
      if (updatedMembers?.length === 0) {
        const newRow = worksheet.addRow([
          rowNumber,
          item.name,
          item?.code,
          item.serverPath,
          item.clientNew?.name,
          titleCase(item.status.toString()),
          managers.map((x: any) => x.user.userData.fullName).join(","),
          "",
          "",
          "",
          "",
          "",
          "",
        ]);
        const mergeEndRowColumn = worksheet.rowCount;
        _.forEach(headerExceljs, (i) => {
          worksheet.getCell(`${i}${mergeEndRowColumn}`).border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
          };
        });

        newRow.eachCell((cell) => {
          cell.font = {
            size: 12,
            bold: false,
            color: { argb: "233E7C" },
          };
        });

        newRow.height = 25;
        newRow.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      }

      if (totalColumn > 0) {
        const mergeStartRowColumn = worksheet.rowCount - totalColumn + 1;
        const mergeEndRowColumn = worksheet.rowCount;
        _.forEach(headerExceljs, (i) => {
          for (let x = mergeStartRowColumn; x <= mergeEndRowColumn; x++) {
            worksheet.getCell(`${i}${x}`).border = {
              top: { style: "thin", color: { argb: "000000" } },
              left: { style: "thin", color: { argb: "000000" } },
              right: { style: "thin", color: { argb: "000000" } },
              bottom: { style: "thin", color: { argb: "000000" } },
            };
          }
        });

        worksheet?.mergeCells(mergeStartRowColumn, 1, mergeEndRowColumn, 1);
        worksheet?.mergeCells(mergeStartRowColumn, 2, mergeEndRowColumn, 2);
        worksheet?.mergeCells(mergeStartRowColumn, 3, mergeEndRowColumn, 3);
        worksheet?.mergeCells(mergeStartRowColumn, 4, mergeEndRowColumn, 4);
        worksheet?.mergeCells(mergeStartRowColumn, 5, mergeEndRowColumn, 5);
        worksheet?.mergeCells(mergeStartRowColumn, 6, mergeEndRowColumn, 6);
        worksheet?.mergeCells(mergeStartRowColumn, 7, mergeEndRowColumn, 7);
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "performance.xlsx");
  }

  return (
    <Button
      size="sm"
      onClick={exportToExcel}
      leftIcon={<Icon as={DownloadIcon} />}
      isDisabled={_.isEmpty(filteredProjects)}
      sx={{
        background: "rgba(187, 113, 84)",
        "&:hover": {
          background: "rgba(187, 113, 84,0.5)",
        },
        "&:hover:disabled": {
          background: "rgba(187, 113, 84,0.5)",
        },
        color: "#ffffff",
      }}
    >
      {t("button.export")}
    </Button>
  );
};

export default ExportExcelPerformance;
